import React from 'react';
import useAuth from '../../../../hooks/useAuth';

const NavLeft = () => {
  const { user } = useAuth();
   
  return (
      <React.Fragment>
          <span className="span-company" >{user.company.name}</span>
      </React.Fragment>
  );
};

export default NavLeft;
