import React from 'react';
import { ListGroup } from 'react-bootstrap';
import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";
import useAuth from '../../../../../hooks/useAuth';

const NavGroup = ({ layout, group }) => {
    const { user } = useAuth();
    let navItems = '';

    if (group.children) {
        const groups = group.children;
        navItems = Object.keys(groups).map(item => {
            item = groups[item];
            if (item.rol.indexOf(user.user.typeUser)!== -1) {
              switch (item.type) {
                  case 'collapse':
                      return <NavCollapse key={item.id} collapse={item} type="main" />;
                  case 'item':
                      return <NavItem layout={layout} key={item.id} item={item} />;
                  default:
                      return false;
              }
            } else {
              return false;
            }
        });
    }

    return (
        <React.Fragment>
            <ListGroup.Item as='li' bsPrefix=' ' key={group.id} className="nav-item pcoded-menu-caption"><label className='text-capitalize' >{group.title}</label></ListGroup.Item>
            {navItems}
        </React.Fragment>
    );
};

export default NavGroup;
