const menuItems = {
    items: [
        {
            id: 'navigation',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'time',
                    title: 'Tiempos',
                    type: 'item',
                    icon: 'feather icon-clock',
                    url: 'time',
                    breadcrumbs: false,
                    rol: ['Employee']                   
                },
                {
                  id: 'register',
                  title: 'Registros',
                  type: 'collapse',
                  icon: 'feather icon-clock',
                  url: 'register',
                  rol: ['Admin'],
                  children: [
                        {
                            id: 'time',
                            title: 'Tiempos',
                            type: 'item',
                            url: 'time',
                            breadcrumbs: false,
                            rol: ['Admin']                   
                        },
                        {
                            id: 'timeCam',
                            title: 'WebCam',
                            type: 'item',
                            url: 'timeCam',
                            breadcrumbs: false,
                            rol: ['Admin']                   
                        }
                    ]
                },
                {
                  id: 'reports',
                  title: 'Reportes',
                  type: 'collapse',
                  icon: 'fas fa-chart-bar',
                  url: 'reportes',
                  rol: ['Admin'],
                  children: [
                    {
                        id: 'reports_detail',
                        title: 'Detallado',
                        type: 'item',
                        url: 'reportes',
                        breadcrumbs: true,
                        rol: ['Admin']
                      },
                      {
                        id: 'summary',
                        title: 'Resumido',
                        type: 'item',
                        url: 'resumido',
                        breadcrumbs: true,
                        rol: ['Admin']
                      },
                      {
                        id: 'dailyReport',
                        title: 'Diario',
                        type: 'item',
                        url: 'diario',
                        breadcrumbs: true,
                        rol: ['Admin']
                      }
                  ]
                },
                {
                    id: 'task',
                    title: 'Tareas',
                    type: 'item',
                    url: 'tareas',
                    icon: 'fas fa-tasks',
                    breadcrumbs: true,
                    rol: ['SuperAdmin','Admin']

                },
                {
                    id: 'schedules',
                    title: 'Horarios',
                    type: 'item',
                    icon: 'feather icon-watch',
                    url: 'horarios',
                    breadcrumbs: true,
                    rol: ['SuperAdmin']
                },
                {
                    id: 'schedules_collapse',
                    title: 'Horarios',
                    type: 'collapse',
                    icon: 'feather icon-watch',
                    url: 'horarios',
                    rol: ['Admin'],
                    children: [
                        {
                            id: 'schedules',
                            title: 'Horarios',
                            type: 'item',
                            url: 'horarios',
                            breadcrumbs: true,
                            rol: ['Admin']

                        },
                        {
                            id: 'scheduleAssignment',
                            title: 'Listado',
                            type: 'item',
                            url: 'lista_horarios',
                            breadcrumbs: true,
                            rol: ['Admin']
                        }
                    ]
                },
                {
                    id: 'license',
                    title: 'licencias',
                    type: 'item',
                    icon: 'feather icon-briefcase',
                    url: 'licencias',
                    breadcrumbs: true,
                    rol: ['Employee']

                },
                {
                    id: 'license_collapse',
                    title: 'Licencias',
                    type: 'collapse',
                    icon: 'feather icon-briefcase',
                    url: 'licencias',
                    rol: ['Admin','SuperAdmin'],
                    children: [
                        {
                            id: 'type_license',
                            title: 'Tipos',
                            type: 'item',
                            url: 'tipo_licencias',
                            breadcrumbs: true,
                            rol: ['Admin','SuperAdmin']

                        },
                        {
                            id: 'license',
                            title: 'Licencias',
                            type: 'item',
                            url: 'licencias',
                            breadcrumbs: true,
                            rol: ['Admin','SuperAdmin']

                        },
                        {
                            id: 'license_report',
                            title: 'Reporte',
                            type: 'item',
                            url: 'licencias_reporte',
                            breadcrumbs: true,
                            rol: ['Admin','SuperAdmin']

                        }
                    ]
                },
                {
                    id: 'user-profile',
                    title: 'Usuarios',
                    type: 'item',
                    icon: 'feather icon-users',
                    url: 'usuarios',
                    breadcrumbs: true,
                    rol: ['Admin','SuperAdmin']

                },
                {
                    id: 'business-profile',
                    title: 'Empresas',
                    type: 'item',
                    icon: 'far fa-building',
                    url: 'empresas',
                    breadcrumbs: true,
                    rol: ['SuperAdmin']

                },
                {
                    id: 'countries',
                    title: 'Países',
                    type: 'item',
                    icon: 'fas fa-globe-europe',
                    url: 'paises',
                    breadcrumbs: true,
                    rol: ['SuperAdmin']

                },
                {
                    id: 'perfil',
                    title: 'Perfil',
                    type: 'item',
                    icon: 'fas fa-globe-europe',
                    url: 'perfil',
                    breadcrumbs: true,
                    rol: ['']

                }


            ]

        }
    ]
}


export default menuItems