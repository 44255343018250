import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    error: null,
    loading: false,
    message: null
}

const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        actionPending: (state, {payload}) => {
            state.loading = !state.loading
            state.message = null
            state.error = null
        },
        showErrors: (state, { payload }) => {
            state.error= payload
        },
        showMessage: (state, { payload }) => {
            state.message = payload
        }
    }
})

const { reducer, actions } = statusSlice
export const { actionPending, showErrors, showMessage } = actions

export default reducer