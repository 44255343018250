import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import { JWTProvider } from "./contexts/JWTContext";
import { FormContextProvider } from './contexts/formContext'
import routes, { renderRoutes } from "./routes";
import {BASENAME} from './config/constant';

const App = () => {
  return(
    <React.Fragment>
      <Router basename={BASENAME}>
        <JWTProvider>
          <FormContextProvider>
             {renderRoutes(routes)}
            </FormContextProvider>
        </JWTProvider>
      </Router>
    </React.Fragment>
  )
}

export default App;
