import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'

import { ACCOUNT_INITIALISE, LOGIN, LOGOUT } from "../store/actions";
import axios from '../service/axios'
import accountReducer from '../store/accountReducer';
import Loader from '../components/Loader/Loader'

const initialState = {
    isLoggedIn: false,
    isInitialised: false,
    user:null
}

const verifyToken = (token) => {
    if(!token){
        return false
    }
     const decoded = jwtDecode(token);
     return decoded.exp > (Date.now() / 10000);

}
const setSession = (token) => {
  if (token) {
    localStorage.setItem('serviceToken', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};
const JWTContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => { }
})

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

  const login = async (username, password) => {
      try {    
        const response = await axios.post('/auth', { username, password })
        if (response === undefined) {
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null
            }
          });
        } else {
          const { token, user } = response.data;
          setSession(token);
          dispatch({
            type: LOGIN,
            payload: {
              user
            }
          });
        }
      }catch(err){
        if (!err.message) {
          alert("Verificar acceso a internet e intente nuevamente")
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null
            }
          });
        } else {
          return err;
        }
        
      }
      
    
    };
    
    const logout = () => {
      setSession(null);
      dispatch({ type: LOGOUT });
    };

    useEffect(() => {
      const init = async () => {
        try {
          const token = window.localStorage.getItem('serviceToken');
          if (token && verifyToken(token)) {
            setSession(token);
            const response = await axios.get('/auth/account/me');
            const user = response.data;
            dispatch({
              type: ACCOUNT_INITIALISE,
              payload: {
                isLoggedIn: true,
                user
              }
            });
          } else {
            dispatch({
              type: ACCOUNT_INITIALISE,
              payload: {
                isLoggedIn: false,
                user: null
              }
            });
          }
        } catch (err) {
          console.error(err);
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null
            }
          });
        }
      };    
               
      init();  
    }, []);

    if (!state.isInitialised) {
      return <Loader />;
    }

    return (
      <JWTContext.Provider value={{ ...state, login, logout}}>
          {children}
      </JWTContext.Provider>
    )
}

export default JWTContext