import { createContext, useState } from "react";



const FormContext = createContext({});




export const FormContextProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [message] = useState(null);
  const [loading] = useState(false);
  
 
  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  

 
  return (
    <FormContext.Provider value={{
      formData,
      errors,
      message,
      loading,
      selectedFile,
      setFormData,
      setErrors,
      handleChange,
      setSelectedFile
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
