import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoggedIn: false,
    isInitialised: false,
    user: {},
    isLoading: false,
    error: "",
    imageProfile: null
}


const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        account_initalise: (state, { payload }) => {
            state.isLoggedIn = payload.isLoggedIn
            state.isInitialised= true
            state.user = payload.user
        },
        loginPending: (state) => {
            state.isLoading= !state.isLoading
        },
        loginUser: (state, {payload}) => {
            state.isLoggedIn = true
            state.user = payload
            state.isLoading= false
        },
        loginFail: (state, {payload}) => {
            state.isLoading= false
            state.error= payload
        },
        logoutUser: (state)  => {
            state.isLoggedIn = false
            state.user = null
        },
        changeImageProfile: ((state, { payload }) => {
            state.imageProfile = payload
        })

    }
})

const {reducer, actions} = accountSlice
 export const {account_initalise , loginPending, loginUser, loginFail, logoutUser, changeImageProfile } = actions

 export default reducer