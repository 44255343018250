import axios from 'axios'
// import  useToken  from '../hooks/useToken'


const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_DOMINIO,
    timeout: 100000
});


axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error.message && error.response.data),
    (req) => {
        const token = window.localStorage.getItem('serviceToken');
      
        if(token){
            req.headers.Authorization = `Bearer ${token}`;
        }
        return req;
    }
    
);

export default axiosServices