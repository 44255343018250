export const BASENAME= '/';
export const BASE_URL = '/';
export const BASE_TITLE = ' | LexControl Timer';

export const CONFIG = {
  layout: 'vertical', // vertical, horizontal
  subLayout: '', // horizontal-2
  collapseMenu: true, // mini-menu
  layoutType: 'menu-light', // menu-dark, menu-light, dark
  headerBackColor: 'header-blue', // header-blue, header-red, header-purple, header-info, header-green header-dark
  rtlLayout: false,
  navFixedLayout: true,
  headerFixedLayout: true,
  boxLayout: false,
};

export const ROLE = [
  {
    name: "Admin",
    value: "Administrador"
  },
  {
    name: "Employee",
    value: "Empleado"
  }];

export const DAYS_OF_WEEK = [
  {
    label: 'Domingo',
    value: 0
  },
  {
    label: 'Lunes',
    value: 1
  },
  {
    label: 'Martes',
    value: 2
  },
  {
    label: 'Miercoles',
    value: 3
  },
  {
    label: 'Jueves',
    value: 4
  },
  {
    label: 'Viernes',
    value: 5
  },
  {
    label: 'Sábado',
    value: 6
  }];