import React, { useContext, useEffect } from 'react';
import { ListGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {ConfigContext} from "../../../../contexts/ConfigContext";
import useAuth from '../../../../hooks/useAuth';


import avatar from '../../../../assets/images/user_1.png'
import { useDispatch, useSelector } from 'react-redux';
import { changeImageProfile } from "../../../../reducer/accountSlice";



const NavRight = () => {
    const configContext = useContext(ConfigContext);
    const { user, logout } = useAuth();
    const { rtlLayout } = configContext.state;
    const { imageProfile } = useSelector((state) => state.account);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user.imageProfile) {
            dispatch(changeImageProfile(user.imageProfile))
        } else {
            dispatch(changeImageProfile(avatar))
        }
    },[dispatch, user.imageProfile])

    const handleLogout = async () => {
        try {
            //handleClose();
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <React.Fragment>
            <ListGroup as='ul' bsPrefix=' ' className="navbar-nav ml-auto">
                <ListGroup.Item as='li' bsPrefix=' '>
                    <Dropdown alignRight={!rtlLayout} className="drp-user">
                        <Dropdown.Toggle as={Link} variant='link' to='#' id="dropdown-basic">
                            <img src={imageProfile} className="img-radius img-profile " alt="User Profile"/>
                            <span className="span-user">{user.surname} {user.name}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="profile-notification">
                            <div className="pro-head">
                                <img src={imageProfile} className="img-radius img-profile" alt="User Profile" />
                                <span>{user.surname} {user.name}</span>
                                <Link to='#' className='dud-logout' title='Cerrar Sesión' onClick={handleLogout}>
                                  <i className="feather icon-log-out"/>
                                </Link>
                            </div>
                            <ListGroup as='ul' className="pro-body">
                              <ListGroup.Item as='li' bsPrefix=' '><Link to='/perfil' className="dropdown-item" ><i className="feather icon-user"/> Perfil</Link></ListGroup.Item>
                              <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item" onClick={handleLogout}><i className="feather icon-log-out"/> Cerrar Sesión</Link></ListGroup.Item>
                            </ListGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item>
            </ListGroup>
        </React.Fragment>
    );
};

export default NavRight;
