import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    endHour: null,
    initHour: null,
    startDate: null
}

const statusSlice = createSlice({
    name: 'time',
    initialState,
    reducers: {
        setEndHour: (state, {payload}) => {
            state.endHour = payload
        },
        setInitHour: (state, {payload}) => {
            state.initHour = payload
        },        
        setStartDate: (state, {payload}) => {
            state.startDate = payload
        },
        cleanState: (state) => {
             state.endHour = null
             state.initHour = null
             state.startDate = null
        }
    }
})

const { reducer, actions } = statusSlice
export const { setEndHour,setInitHour, setStartDate, cleanState } = actions

export default reducer