import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';


const GuestGuard = ({ children }) => {
    const { isLoggedIn, user } = useAuth();
    
    if (isLoggedIn) {
      if (user.user.typeUser==='SuperAdmin') {
        return <Redirect to="/usuarios" />;
      } 
      return <Redirect to="/time" />;
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default GuestGuard;