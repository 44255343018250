import {
    useDispatch as useReduxDispatch,
    useSelector as useReduxSelector
} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import reducers from './reducers';
import status from '../reducer/statusSlice'
import account from '../reducer/accountSlice'
import time from '../reducer/timeSlice'

const store = configureStore({
    reducer: {
        reducers,
        status,
        account,
        time
        
    },
    devTools: true
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
