import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

import GuestGuard from "./components/Auth/GuestGuard";
import AuthGuard from './components/Auth/AuthGuard'

import { BASE_URL } from "./config/constant";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: lazy(() => import('./views/auth/login') )
    },
    {
        path: '*',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/time',
                component: lazy (() => import('./views/Time/index'))
         
            },
            {
              exact: true,
              path: '/timeCam',
              component: lazy (() => import('./views/TimeCam/index'))
       
            },
            {
                 exact: true,
                 path: '/reportes',
                 component: lazy (() => import('./views/Reporte'))
         
            },
            {
                 exact: true,
                 path: '/resumido',
                 component: lazy (() => import('./views/Resumen'))
         
            },
            {
                exact: true,
                path: '/diario',
                component: lazy (() => import('./views/ReporteDiario'))
        
            },
            {
                 exact: true,
                 path: '/usuarios',
                 component: lazy (() => import('./views/User/index'))
         
            },
            {
                 exact: true,
                 path: '/perfil',
                 component: lazy (() => import('./views/Profile/index'))
         
            },
            {
                exact: true,
                path: '/empresas',
                component: lazy (() => import('./views/Company/index'))
        
          },
          {
            exact: true,
            path: '/paises',
            component: lazy (() => import('./views/Country/index'))
    
          },
          {
            exact: true,
            path: '/tareas',
            component: lazy (() => import('./views/Tasks/index'))
    
          },
          {
            exact: true,
            path: '/horarios',
            component: lazy (() => import('./views/Schedules/index'))
    
          },
          {
            exact: true,
            path: '/lista_horarios',
            component: lazy(() => import('./views/ScheduleAssignment/index'))    
          },
          {
            exact: true,
            path: '/tipo_licencias',
            component: lazy(() => import('./views/TypeLicenses/index'))    
          },
          {
            exact: true,
            path: '/licencias',
            component: lazy(() => import('./views/Licenses/index'))    
          },
          {
            exact: true,
            path: '/licencias_reporte',
            component: lazy(() => import('./views/ReporteLicencia'))    
          },
          {
            path: '*',
            exact: true,
            component: () => <Redirect to={BASE_URL}/>
          }

        ]
    }
   
]

export default routes